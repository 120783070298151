import Vue from 'vue'
import Vuex from 'vuex'
import library from './home/library'
import assignment from './home/assignment'
import assignmentUser from './home/assignmentUser'
import article  from './home/article'
import video from './home/video'
import lessonlearn  from './home/lessonlearn'
import level from './user-management/level'
import user from './user-management/user'
import group from './user-management/group'
import sound from './home/sound'
import quiz from './home/quiz'
import departmentone from './user-management/departmentone'
import usercoursestatusreport from './user-management/usercoursestatusreport'
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        library: library,
        article: article,
        assignmentUser:assignmentUser,
        assignment:assignment,
        video: video,
        level: level,
        lessonlearn:lessonlearn,
        user: user,
        group: group,
        sound:sound,
        quiz:quiz,
        departmentone: departmentone,
        usercoursestatusreport: {
            ...usercoursestatusreport,
            namespaced: true, // ✅ Important! Enable namespaced module
          },
    },
    state: {
        event: {},
        userManagementTab: 'tab-1',
    },
    getters: {
        event(state) { return state.event},
        tabs(state) {return state.userManagementTab},
    },
    

    mutations: {
        addEvent(state,data){
            state.event = data
        },
        changeTab(state,data){
            state.userManagementTab = data
        },
        
    }
})

export default store