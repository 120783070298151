const usercoursestatusreport = {
  namespaced: true, // ✅ Fix: This makes the module properly namespaced
  state: {
    activeTab: "tab-1", // Default tab
  },
  mutations: {
    SET_TAB(state, tab) {
      state.activeTab = tab;
      console.log("Vuex Updated - Current Active Tab:", state.activeTab);
    },
    RESET_TAB(state) {
      state.activeTab = "tab-1";
      console.log("Vuex Reset - Default Tab Set:", state.activeTab);
    },
  },
  actions: {
    changeTab({ commit }, tab) {
      commit("SET_TAB", tab);
    },
    resetTab({ commit }) {
      commit("RESET_TAB");
    },
  },
};

export default usercoursestatusreport;
